import './polyfill';

import {
  qs,
  qsAll,
} from './dom-helpers';

import smoothAnchors from './smooth-anchors';
// import Slider from './modules/Slider';
import ToTopButton from './modules/ToTopButton';
import Header from './modules/Header';
import Accordion from './modules/Accordion';
import AccordionTabs from './modules/AccordionTabs';

class Main {
  constructor() {
    this.forms = qsAll('.form');
    this.accordions = qsAll('.accordion__container');
    this._run();
  }

  _run() {
    try {
      this._registerGlobalObjects();
      this._registerAccordions();
      new Header();
      // new Slider();
      new ToTopButton();
      new AccordionTabs();
      // eslint-disable-next-line new-cap
      new smoothAnchors();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerGlobalObjects() {
    window.qs = qs;
    window.qsAll = qsAll;
  }

  _registerAccordions() {
    this.accordions.forEach((el) => new Accordion(el));
  }
}

document.addEventListener('DOMContentLoaded', () => new Main());
